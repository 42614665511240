<template>
    <default-layout class="errorPage is-404">
        <b-icon icon="poo"></b-icon>
        <h1>You got 404'd</h1>
        <p>The page you're looking for does not exist. Sorry :'(</p>
    </default-layout>
</template>

<script>
export default {
    name: 'Error404',
}
</script>
